import React, { useState, useContext } from "react"
import "./header.css"
import { nav } from "../../data/Data"
import { Link, useNavigate } from "react-router-dom"
import { Avatar, Box, Menu } from "@mui/material"
import { AuthContext } from "../../../context/AuthContext"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [navList, setNavList] = useState(false)

  const {dispatch} =  useContext(AuthContext);

  const {currentUser} = useContext(AuthContext);

  const handleLogout = () =>{
    console.log('Logout')
    dispatch({type:"LOGOUT"})
    navigate("/login");
  }


  const navigate =  useNavigate();

  return (
    <>
      <header style={{height:'100%'}}>
        <div className='container flex'>
         <Link to='/'>
          <div className='logo'>
            <img src='./images/logo-light.png' alt='' />
          </div>
         </Link>
          <div className='nav'>
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path}>{list.text}</Link>
                </li>
              ))}
              <li>
                <Box
                display = {{xs: 'block !important', sm:'block !important', md:'none !important', lg:'none !important'}}
                color='white'
                ml= {4}
                onClick={handleLogout}
                >
                  Logout
                </Box>
              </li>
            </ul>
          </div>
          <Box
          display = {{xs: 'none !important', sm:'none !important', md:'block !important', lg:'block !important'}}
          >
          <button onClick={handleLogout}>
          Logout
          </button>
          </Box>
          <div className='toggle'>
            <button style={{backgroundColor:'white', color:'black'}} onClick={() => setNavList(!navList)}>{navList ? <CloseIcon /> : <MenuIcon style={{fontSize:'150%'}}/>}</button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
