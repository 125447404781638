import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material'
import React, {useState, useContext, useEffect} from 'react';
import {auth} from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import {AuthContext} from '../../context/AuthContext'; 
import { signInWithEmailAndPassword } from 'firebase/auth';

function Login() {

    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    

    const navigate =  useNavigate();
  
  const {dispatch} =  useContext(AuthContext);

    const handleLogin = (e) =>{
        e.preventDefault();
        console.log('email: ' + email + ' password: ' + password);
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log('user ' + {...user});
          dispatch({type:"LOGIN", payload: user})
          navigate("/");
        })
        .catch((error) => {
          console.log(error.message);
          setError(true)
        });
        setEmail('');
        setPassword('');
     }
   

  return (

    <Grid container style={{backgroundColor:'#F0F2F5', height:'100vh'}}>
      <Box 
      height={{xs: '30%', sm: '25%', md: '60%', lg:'70%'}}
      className='login'
      
      >
            <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'1%'}}>
             <center>
              <Typography style={{fontWeight:'bold', fontSize:'5vw', color:'#efb42c'}}>Login</Typography>
             </center>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5%'}}>
              <center>
                <TextField size='small' label='Email' type={'email'} onChange={e=>setEmail(e.target.value)}/>
              </center>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{margin:'5%'}}>
              <center>
                <TextField size='small' label='Password' type={'password'} onChange={e=>setPassword(e.target.value)}/>
              </center>
            </Grid>
            <center>
                <Button color='primary' varient='outlined' 
                    style={{backgroundColor:'#224876', color:'white', borderRadius:'25px', padding:'2%', width:'30%', margin:'3%'}}
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </center>
            <center>
              { error && <span>Wrong email or password!</span>}
            </center>
        </Box>
    </Grid>
  )
}

export default Login