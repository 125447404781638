import React from "react";
import "./contact.css";
import { useRef } from 'react'; 
import emailjs  from '@emailjs/browser'
const Contact = () => {
   const form = useRef();
   const sendEmail = (e) =>{
    e.preventDefault();
    emailjs.sendForm('service_ttrmrd8','template_g7cjue8',form.current, 'Aw7SL_7LrdkkkU0ng').then((result)=>{
      console.log(result.text)
    },(error)=>{
      console.log(error.text)
    });
   };
  return (
    <>
      <section className='contact mb' style={{marginTop:'4%'}}>
        <div className='container'>
          <form className='shadow'  ref={form} onSubmit={sendEmail} >
            <h4>Fillup The Form</h4> <br />
            <input type='text' placeholder='Name' name="user_name" style={{padding:'1%'}}/>
            <input type='text' placeholder='Email' name="user_email" style={{padding:'1%'}}/>
            <input type='text' placeholder='Subject' style={{padding:'1%'}}/>
            <textarea placeholder='Write your request here' name="message" cols='30' rows='10' style={{padding:'1%'}}></textarea>
            <button  style={{backgroundColor:'#1a2138', color:'white', border:'none', padding:'1%'}}>Submit Request</button>
          </form>
        </div>
      </section>
    </>
  )
}

export default Contact