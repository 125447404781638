import React from "react"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../common/Heading"
import Heading3 from "../../common/Heading3"
import { team } from "../../data/Data"
import "./team.css"

const Team = () => {

  const navigate = useNavigate();

  const handleNavigate = (val) =>{
    if(val.name ==  'Kiram Tadesse'){
      console.log(val);
      navigate('/kiram_detail',{ state:{data : val}})
    }else if(val.name == 'TAMRAT BAYLE (Dr. Eng.)'){
      navigate('/tamrat_detail',{ state:{data : val}})
    }
    else{
      console.log(val);
      navigate('/team_detail',{ state:{data : val}})
    }
  }

  return (
    <>
      <section className='team background' style={{backgroundColor:'white'}}>
        <div className='container'>
          <Heading3 title='Our Team' subtitle='The core and associate staff members of UCS have extensive experience in various fields of consultancy, research and training activities. The composition include professional experts with proven track record of experience in organizational change and transformation, project management, strategic planning and management, corporate governance and leadership, information technology (IT), and development management.' />
          <center>
          <div className='content mtop grid3'>
            {team.map((val, index) => (
              <div className='box' key={index}>
                <div className='details'>
                  <div className='img'>
                    <img src={val.cover} alt='' />
                    <i className='fa-solid fa-circle-check'></i>
                  </div>
                  <i className='fa fa-location-dot'></i>
                  <h4>{val.name}</h4>
                  <label>{val.honor}</label>
                  <label>{val.position}</label>
                     
                  <center>
                      <div className='button flex'>
                        {val.name == 'Fanta Tesgera (PhD)' && <button onClick={(e) => handleNavigate(val)} style={{margin:'2% auto'}}>
                        Detail
                        </button>}
                        {
                          val.name == 'Kiram Tadesse' && <button onClick={(e) => handleNavigate(val)} style={{margin:'2% auto'}}>
                        Detail
                        </button>
                        }
                        {
                          val.name == 'TAMRAT BAYLE (Dr. Eng.)' && <button onClick={(e) => handleNavigate(val)} style={{margin:'2% auto'}}>
                        Detail
                        </button>
                        }
                      </div>
                  </center>
                </div>
              </div>
            ))}
          </div>
          
          </center>
        </div>
      </section>
    </>
  )
}

export default Team
