import React from "react"
import Heading1 from "../../common/Heading1"
import Heading2 from "../../common/Heading2"
import "./partners.css";
import Awash from '../../resources/awash.png';
import AwashInsurance from '../../resources/awashInsurance.jpg';
import Dse from '../../resources/dse.png';
import EWT from '../../resources/ewt.png';
import Gebecon from '../../resources/gebecon.png';
import Gibrina from '../../resources/mg.png';
import Global from '../../resources/global.jpg';
import Kenera from '../../resources/kenera.png';
import Minaye from '../../resources/minaye.png';
import Oromia from '../../resources/oromia.png';
import PeopleCouncil from '../../resources/peoplecouncil.png';

const clients = [
  {img: Awash},
  {img: AwashInsurance},
  {img: Oromia},
  {img: Global},
  {img: Kenera},
  {img: Gebecon},
  {img: Gibrina},
  {img: Minaye},
  {img: Dse},
  {img: PeopleCouncil},
  {img: EWT},
  
]

const Partners = () => {
  return (
    <div style={{backgroundColor:'#ffffff'}}>
      
      <section className='partners ' style={{margin:'3% 0%'}} >  
        <div className='container'>
            
            <Heading1   title='PARTNERSHIP' subtitle='UCS partners and works closely with well-established consulting firms both local and overseas (such as Glocal Management Partners, B and M Development Consultants, JEPICS, Halkago Connect and Zinger Solutions Limited). UCS also partners with experts from multidisciplinary specializations to meet client needs on training, advisory, and research related services.' />
            <Heading2 title='SOME OF OUR CLIENTS' subtitle='' style={{margin:'3%'}}/>
            <center>
            {
              clients.map((client)=>{
                return <img src={client.img} alt='Image not found' style={{width:'20%', margin:'3%', height:'60%', borderRadius:'0'}}/>
              })
            }
            </center>
        </div>
      </section>
    </div>
  )
}

export default Partners
