import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import Team from "../home/team/Team"
import img from "../images/about.png"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back title='About Us - who we are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Check out our company story and services' />

            <p>
              Ultimate Consultancy Services (UCS) was established in 2012 G.C. with a mission to make a positive difference in organizations and individuals’ life through the provision of value adding advisory and training services.
            </p>
            <p>
             As bigger wave of changes are looming on the horizon, organizations are likely to face multifaceted challenges that will continue impacting every facet of their activities. The implications of the current winds of change are far reaching unless organizations strategically get prepared both in the near and distant future. Among others, the unprecedented growth in competition and globalization, triggered by recent advances in technology, is demanding organizations to continually renew and modernize the way they do business to compete and thrive on a global scale. 
            </p>
            <p>
            UCS, thus, primarily works to support organizations in order to effectively design and implement strategic organizational change and transformation initiatives in response to the profound changes that are taking place in many frontiers. UCS, through its structured and organized approach in its advisory and training services, has the mission to inspire organizations for change and transformation through the integration and application of the latest knowledge, skills, tools and techniques available in the relevant field. 
            </p>
            <p>
            While providing its service, UCS brings in the professional expertise (the most up-to-date knowledge, skills, and experiences) needed by organizations in their endeavor to change and transform along the latest developments. UCS believes that its mission will come true as it continually and closely work with its esteemed customers (organizations) in steering strategic mindset changes (the need for organizational change and transformation) that keeps them moving with the changing world. 
            </p>
            <Heading title='Mission' subtitle='' />
            <p>
              UCS envisions becoming a change and transformation catalyst within organizations in Ethiopia and beyond.
            </p>
            <Heading title='Vision' subtitle='' />
            <p>
            The mission of UCS is to conduct research, deliver trainings, advise and support organizations in the attainment of their corporate strategic goals and objectives both in the short and long term.

            </p>
          </div>
          
        </div>
        <Team />
      </section>
    </>
  )
}

export default About
