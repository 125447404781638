import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route, Routes, Navigate } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
import Blog from "../blog/Blog"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import ViewNews from "../news/ViewNews"
import { AuthContext } from "../../context/AuthContext"
import Login from "../login/Login"
import { useContext } from "react"
import MoreNews from "../news/MoreNews"
import TeamDetail from "../home/team/TeamDetail"
import KiramDetail from "../home/team/KiramDetail"
import TamratDetail from "../home/team/TamratDetail"

const Pages = () => {

  const {currentUser} = useContext(AuthContext);

  const RequireAuth = ({children})=>{
    return currentUser ? (children) : <Navigate to="/login"/>
  }

  return (
    <>
        {currentUser && <Header />}
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route exact path='/' element={ 
            <RequireAuth>
              <Home/>
            </RequireAuth>
          }  />
          <Route exact path='/about'  element={ 
            <RequireAuth>
              <About/>
            </RequireAuth>
          }  />
          <Route exact path='/services'  element={ 
            <RequireAuth>
              <Services/>
            </RequireAuth>
          }/>
          <Route exact path='/blog'  element={ 
            <RequireAuth>
              <Blog/>
            </RequireAuth>
          } />
          <Route exact path='/contact'  element={ 
            <RequireAuth>
              <Contact/>
            </RequireAuth>
          } />
          <Route exact path='/viewNews'  element={ 
            <RequireAuth>
              <ViewNews/>
            </RequireAuth>
          } />
          <Route exact path='/detail'  element={ 
            <RequireAuth>
              <MoreNews/>
            </RequireAuth>
          } />
          <Route exact path='/team_detail'  element={ 
            <RequireAuth>
              <TeamDetail/>
            </RequireAuth>
          } />
          <Route exact path='/kiram_detail'  element={ 
            <RequireAuth>
              <KiramDetail/>
            </RequireAuth>
          } />
          <Route exact path='/tamrat_detail'  element={ 
            <RequireAuth>
              <TamratDetail/>
            </RequireAuth>
          } />
        </Routes>
         {currentUser && <Footer />}
    </>
  )
}

export default Pages
