import React,{useContext} from "react"
import Back from "../common/Back"
import RecentCard from "../home/recent/RecentCard"
import "../home/recent/recent.css"
import img from "../images/news.JPG"
import { AuthContext } from "../../context/AuthContext"
import { Link } from "react-router-dom"

const Blog = () => {
  const {currentUser} = useContext(AuthContext);
  return (
    <>
      <section className='blog-out mb'>
        <Back title='News' cover={img} />
        {
          currentUser &&  <Link to='/viewNews'>
                            <button style={{margin:'2% 10%'}}>Add News</button>
                          </Link>
        }
        <div className='container recent' style={{height:'100vh'}}>
          <RecentCard style={{marginBottom:'10%'}}/>
        </div>
      </section>
    </>
  )
}

export default Blog
