import { Box, Button, Grid, Stack} from '@mui/material';
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAdd} from '@fortawesome/free-solid-svg-icons'
import AddNews from './AddNews';
import NewsTable from './NewsTable';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

function ViewNews(props) {
    const [add, setAdd] = useState(false);
    const [side, setSide] = useState(0);
    const [New, setNew ]=useState([]);
    const [News, setNews ]=useState([]);

    
    useEffect(()=>{
      if(props.sidebar){
        setSide(10)
      }
      else{
        setSide(0)
      }
    },[props.sidebar])
    useEffect(()=>{
      if(props.sidebar){
        setSide(10)
      }
      else{
        setSide(0)
      }
    },[props.sidebar])

    useEffect(() => {
      const q = query(collection(db, "news"), orderBy('timeStamp', 'desc'));
      const unsub = onSnapshot(q, (snapShot) => {
        let list = [];
        snapShot.docs.forEach(doc=>{
          list.push({id: doc.id, ...doc.data()});
        })
        setNews(list);
    },(error)=>{
       console.log(error);
    });
    return () =>{
      unsub();
    }
    }, []);
    
    
    const [addBtn, setAddBtn] = useState(true);
    const handleAddBtn = () =>{
      if(addBtn){
         setAddBtn(false)
      }else{
        setAddBtn(true)
      }
    }

    const handleAdd = () =>{
       if(add) {
        setAdd(false)
       }else{
        setAdd(true)
       }
    }

  return (
    <div >
    <Box>
    <Grid xs={12} sm={12} md={12} lg={12}>
    <Box style={{ margin:'5%'}}>
    {
      add ? 
      
       <center >
        <AddNews setAdd={setAdd} sx={{m:3}}/> 
       </center>
      :
      null
    }
        <center>
            <Button  style={{backgroundColor:'#224876'}} startIcon={addBtn && <FontAwesomeIcon icon={faAdd} style={{marginBottom:10}}/>} variant='contained' color='primary' sx={{m:3}}
            onClick={
              (e)=>{
                handleAdd()
                handleAddBtn()}
              }
              >
            {addBtn ? `Add News` :`Cancel`} 
            </Button>
            <NewsTable rows={News} />
        </center>  
      </Box>
      </Grid>
    </Box>
    </div>
  )
}

export default ViewNews